import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Header } from 'components';
import { Layout, Container } from 'layouts';

const About = _ => {
  return (
    <Layout>
      <Helmet title={'A propos'} />
      <Header title="2B2S">A propos</Header>
      <Container center={false}>
        <h3>
          Contexte
      </h3>
        <p>
              Les avancées technologiques en médecine et en biologie ont permis ces dernières années l’accumulation de quantités colossales de données (big data). L’analyse de ces données grâce aux développements constants dans le domaine de l’informatique, sont à l’origine de véritables révolutions dans tous les secteurs des sciences de la vie. Depuis quelques années, l’élément limitant n’est plus la génération des big data mais leur exploitation correcte. La production de ces données (séquençage d’ADN, transcriptomique, protéomique, imagerie) peut être locale ou externalisée. A l'inverse, l’exploitation des big data à des fins de recherche requiert des ajustements fins des paramètres d’analyse, une optimisation des pipelines, la multiplication de contrôles ou la création de nouveaux outils. Ainsi, la plateforme 2B2S privilégie le maintien, le développement, et la mise en commun de compétences bioinformatiques locales pour l’analyse des données massives en biologie. La proximité et le dialogue entre les bioinformaticien(ne)s et les chercheurs en biologie et en médecine permet l’analyse approfondie de données de RNAseq, de métabolomique, de composition du microbiote digestif, de cancérologie. L’augmentation du niveau de connaissance bioinformatique permet aux chercheurs d’envisager des recherches plus ambitieuses, plus structurées et plus collaboratives. 
      </p>
      </Container>
    </Layout>
  );
}

export default About;

About.propTypes = {
  center: PropTypes.object,
};

About.defaultProps = {
  center: true
};
